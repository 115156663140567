<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
      <li class="breadcrumb-item">
        <a href="/app/ordem-servico">Ordens de serviços</a>
      </li>
      <li class="breadcrumb-item">
        <a @click="goBack" style="cursor: pointer">Envelopamento</a>
      </li>
      <li class="breadcrumb-item">Objetos concluídos</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header" @click="loadItens">Objetos concluídos</h1>
    <template v-if="isLoad">
      <panel :noButton="true">
        <div
          slot="header"
          class="w-100 d-flex align-items-center justify-content-between"
        >
          <span>Listagem de objetos concluídos</span>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :lineNumbers="false"
          :styleClass="'vgt-table striped'"
          :search-options="{ enabled: true, placeholder: 'Pesquisar' }"
          :pagination-options="pageOptions"
        >
          <div slot="emptystate" class="w-100 text-center">
            Sem registros encontrados
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'os'">
              {{ props.row.ordem_servico_id.toString().padStart(6, '0') }}
            </span>
            <span v-if="props.column.field === 'nome_arquivo'">
              {{ props.row.ordem_servico.nome_arquivo.slice(0, -4) }}
            </span>
            <span v-if="props.column.field === 'sequencia_objeto'">
              {{ props.row.sequencia_objeto.toString().padStart(6, '0') }}
            </span>

            <span v-if="props.column.field === 'caixa'">
              {{ props.row.caixa.toString().padStart(3, '0') }}
            </span>
            <span v-if="props.column.field === 'em_producao'">
              {{ props.row.dt_producao | filterDate('DD/MM/yyyy HH:mm:ss') }}
            </span>
            <span v-if="props.column.field === 'envelopadora'">
              {{ props.row.envelopadora }}
            </span>
          </template>
        </vue-good-table>
      </panel>
    </template>
    <template v-else>
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </template>
    <!-- END page-header -->

    <modal-impressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
    <modal-reimpressao v-if="ordem" :loadItens="loadItens" :ordem="ordem" />
  </div>
</template>

<script>
import axios from 'axios'
import ModalImpressao from './components/ModalImpressao.vue'
import ModalReimpressao from './components/ModalReimpressao.vue'

export default {
  components: {
    ModalImpressao,
    ModalReimpressao
  },
  data() {
    return {
      isLoad: false,
      ordem: undefined,
      columns: [
        {
          label: 'os',
          field: 'os',
          sortable: false
        },
        {
          label: 'Nome',
          field: 'nome_arquivo',
          sortable: false
        },
        {
          label: 'Caixa',
          field: 'caixa',
          sortable: false
        },
        {
          label: 'Sequência',
          field: 'sequencia_objeto',
          sortable: false
        },
        {
          label: 'Data Produção',
          field: 'em_producao',
          sortable: false
        },
        {
          label: 'Envelopadora',
          field: 'envelopadora',
          tdClass: 'text-center',
          sortable: false
        }
      ],
      rows: []
    }
  },
  methods: {
    goBack() {
      window.history.back()
    },
    async loadItens() {
      this.isLoad = false
      await axios
        .get(this.apiUrl)
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.rows = res.data
          this.isLoad = true
        })
    }
  },
  computed: {
    apiUrl() {
      return `producao/rastreabilidade/listar-concluidos?per_page=9999&page=1&ordem_servico=${this.$route.params.id}&caixa=${this.$route.params.caixa}&status=DUPLICADO`
    },
    pageOptions() {
      return {
        enabled: true,
        mode: 'records',
        perPage: 20,
        position: 'bottom',
        perPageDropdown: [20, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params => `Página atual ${params.firstRecordOnPage}`
      }
    }
  },
  mounted() {
    this.loadItens()
  }
}
</script>
